import React, {useState} from 'react';
import './FormComponent.css'
import {Button, Form, Input, Select, Checkbox, Radio, Space, RadioChangeEvent} from 'antd';
import {checkSponsorhip, fileupload, registerAttendee} from "../../services";
import { useNavigate} from "react-router-dom";
import {AxiosError} from "axios";




    function FormComponent() {
    const [form] = Form.useForm();
    const [value, setValue] = useState('');
    const [checkValue, setCheckValue]= useState<any>('')
    const [checkValue1, setCheckValue1]= useState<any>('')
    const [checkValue2, setCheckValue2]= useState('')
    const [file, setFile] = useState<any>(null);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [inputValue, setInputValue] = useState('')
    const [inputValue1, setInputValue1] = useState('')
    const [inputValue2, setInputValue2] = useState('')
    const [inputUpper, setInputUpper] = useState('');
    const [error, setError] = useState(false);
    const [uploadError, setUploadError]= useState(false)
    const navigate=useNavigate()
    const [loading,setLoading] = useState(false)

    const handleInputUpper =  (event:any) => {
        setInputUpper(event.target.value.toString().toUpperCase());
        form.setFieldsValue({
            "companyName":event.target.value.toString().toUpperCase()
        })
    };
    const handleInputChange = (event:any) => {
        setInputValue(event.target.value);
    }
    const  handleInputChange1 = (event:any)=> {
       setInputValue1(event.target.value);
   }
    const handleInputChange2 = (event:any)=>{
        setInputValue2(event.target.value);
    }
    const onChange = (e: RadioChangeEvent) => {
console.log(e.target.value)
        setValue(e.target.value);
        if(e.target.value==="Other") {
            setIsChecked1(true)
        }else{
            setIsChecked1(false)
        }

    };
    const onCheck = (e:any)=>{
        console.log(e.target.value)
        var updatedList :any = [...checkValue];
        if (e.target.checked) {
            if(e.target.value==="None"){
                updatedList = [ e.target.value];
            }else{
                updatedList = [...checkValue, e.target.value];
                if(checkValue.includes("None")){
                    updatedList.splice(checkValue.indexOf("None"), 1);
                }

            }

        }else{
            updatedList.splice(checkValue.indexOf(e.target.value), 1);
        }

        setCheckValue(updatedList)
        if(e.target.value==="Other") {
            setIsChecked(true)
        }else{
            setIsChecked(false)
        }

        if(e.target.value==="Other" && !e.target.checked){
            setIsChecked(false)
        }
        // console.log(updatedList.toString())
    };
    const onCheck1 = (e:any)=>{

        let updatedList :any = [...checkValue1];
        if (e.target.checked) {
            updatedList = [...checkValue1, e.target.value];
        }else{
            updatedList.splice(checkValue1.indexOf(e.target.value), 1);
        }
        setCheckValue1(updatedList)
        console.log(updatedList)

    };
    const onCheck2 = (e:any)=>{
        setCheckValue2(e.target.value)
        console.log(e.target.value)
        if(e.target.value==="Other") {
            setIsChecked2(true)
        }else{
            setIsChecked2(false)
        }

    };
    const uploadContent = (event:any) => {
        event.preventDefault();
        console.log(event.target.files)
        if (event.target.files[0] && (event.target.files[0].type === 'application/pdf' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png')) {
            setFile(event.target.files[0]);

        }
    };
    const handleSubmit = async () =>{
     let uploadData:any
     let checkValueCash = "";
        for (const checkValueCashKey in checkValue) {
            if(checkValueCashKey !== "0") {
                checkValueCash += ",\n" ;
            }
            checkValueCash += checkValue[checkValueCashKey];
        }
        let checkValueCash1 = "";
        for (const checkValueCashKey1 in checkValue1) {
            if(checkValueCashKey1 !== "0") {
                checkValueCash1 += ",\n";
            }
            checkValueCash1 += checkValue1[checkValueCashKey1];
        }


        try{
        if(file?.type=== 'application/pdf' || file?.type=== 'image/jpeg' || file?.type=== 'image/png'){

            const newData = await checkSponsorhip( {
                "companyName": form.getFieldValue('companyName'),
                "sponsorDesc": checkValueCash+inputValue,
            });
            setLoading(true)
            const formData = new FormData();
            formData.append('file', file);
            console.log(file)

            if(!newData.sponsorship){
                setError(true)

            }
            setTimeout(async() => {
                uploadData = await fileupload(
                    formData,
                )

                const data =  await registerAttendee({
                    "firstName": form.getFieldValue('Fullname'),
                    "lastName": form.getFieldValue('Lastname'),
                    "middleName": "",
                    "patronymic": "",
                    "companyName": form.getFieldValue('companyName'),
                    "positionName": form.getFieldValue('positionName'),
                    "email": form.getFieldValue('email'),
                    "phoneNumber": form.getFieldValue('phoneNumber'),
                    "sponsorDesc": checkValueCash+inputValue,
                    "eventDesc": checkValueCash1,
                    "disabilityDesc": value+ "," + inputValue1,
                    "dietaryDesc": checkValue2 + ","+ inputValue2,
                    "citizenship": form.getFieldValue('citizenship'),
                    "passportNumber": form.getFieldValue('passportNumber'),
                    "fileId": uploadData['result']['fileId']
                })

                form.setFieldsValue({
                    "Fullname": '',
                    "Lastname": '',
                    "companyName": '',
                    "positionName": '',
                    "email": '',
                    "phoneNumber": '',
                    "sponsorDesc": '',
                    "eventDesc": '',
                    "disabilityDesc": '',
                    "dietaryDesc": '',
                    "citizenship": '',
                    "passportNumber": '',
                })
                setCheckValue([])
                setCheckValue1('')
                setCheckValue2('')
                navigate('/success')
                setLoading(false)
            },2000)

            // else {
            //     // setCheckValue(['None'])
            //
            // }


            }
        else{
            setUploadError(true)
        }
        } catch(e){
        if(e instanceof AxiosError){
            navigate('/error')
        }
        }
        // console.log(uploadData['result']['fileId'])
        // data.success ?
        //     setShowSuccess(true) :  setShowError(true)
        // setTimeout(() => {
        //     setShowSuccess(false);},2000)
        // setTimeout(() => {
        //     setShowError(false);},2000)
        }

    return (
        <div className="">
        <p className='textcompl'>Registration is ended!</p>
            {/*<Form className='bodyFormAll' form={form} name="validateOnly" layout="vertical" autoComplete="off" scrollToFirstError={{*/}
            {/*    behavior: 'smooth',*/}
            {/*    block: 'center',*/}
            {/*    inline: 'center',*/}
            {/*}}>*/}
             {/*<div className='bodyForm'>*/}
             {/*    /!*{showSuccess && <SuccessModal/>}*!/*/}
             {/*    /!*{showError&& <ErrorModal/>}*!/*/}
             {/*    /!*<div className='divInput'>*!/*/}
             {/*    /!*    <Form.Item  name="Fullname" label="Full Name" rules={[{ required: true }]} help="First name">*!/*/}
             {/*    /!*        <Input className='inputname' />*!/*/}
             {/*    /!*    </Form.Item>*!/*/}
             {/*    /!*    <Form.Item name="Lastname" label=" " help="Last name">*!/*/}
             {/*    /!*        <Input  className='inputname'/>*!/*/}
             {/*    /!*    </Form.Item>*!/*/}
             {/*    /!*</div>*!/*/}
             {/*    <Form.Item*/}
             {/*        className='inputRight' name="companyName" label="Organization/Company Name" rules={[{ required: true }]}>*/}
             {/*        <Input className='inputname'*/}
             {/*               type="text"*/}
             {/*               value={inputUpper}*/}
             {/*               onChange={handleInputUpper}*/}
             {/*        />*/}
             {/*    </Form.Item>*/}
             {/*    <Form.Item*/}
             {/*        className='inputRight' name="positionName" label="Position/Title" rules={[{ required: true }]}>*/}
             {/*        <Input className='inputname'/>*/}
             {/*    </Form.Item>*/}
             {/*    <Form.Item*/}
             {/*        className='inputRight'*/}
             {/*        label="E-mail"*/}
             {/*        name="email"*/}
             {/*        rules={[{type: 'email',},*/}
             {/*            {*/}
             {/*                required: true,*/}
             {/*                message: 'Please input your E-mail!',*/}
             {/*            },*/}
             {/*        ]}>*/}
             {/*        <Input className='inputname'*/}
             {/*        />*/}

             {/*    </Form.Item>*/}
             {/*    <Form.Item*/}
             {/*        className='inputname'*/}
             {/*        name="phoneNumber"*/}
             {/*        label="Phone Number"*/}
             {/*        rules={[{ required: true}]}>*/}
             {/*        <Input  className='inputname' />*/}
             {/*    </Form.Item>*/}
             {/*    <div className='labelItem'>*/}
             {/*        /!*<Form.Item*!/*/}
             {/*        /!*    name='sponsorDesc'*!/*/}
             {/*        /!*    label="Please indicate if you require sponsorship to attend the event"*!/*/}
             {/*        /!*    rules={[{ required: true }]}*!/*/}
             {/*        /!*    style={{padding:25,paddingLeft:0}}*!/*/}

             {/*        /!*>*!/*/}
             {/*            /!*<div className='checkName' >*!/*/}
             {/*            /!*    <Checkbox checked={checkValue.includes("Flight itinerary") }  type="checkbox"  value="Flight itinerary" name='sponsorDesc' onChange={onCheck}>Flight itinerary</Checkbox>*!/*/}
             {/*            /!*    <Checkbox checked={checkValue.includes("Accomodation") } type="checkbox"  value="Accomodation" name='sponsorDesc' onChange={onCheck}>Accomodation</Checkbox>*!/*/}
             {/*            /!*    <Checkbox checked={checkValue.includes("Visa") } type="checkbox"  value="Visa"  name='sponsorDesc' onChange={onCheck}>Visa</Checkbox>*!/*/}
             {/*            /!*    <Checkbox checked={checkValue.includes("None")} type="checkbox"  value="None"  name='sponsorDesc' onChange={onCheck}>None</Checkbox>*!/*/}
             {/*            /!*    /!*<Checkbox checked={checkValue.includes("Other") } type="checkbox"  value="Other"  name='sponsorDesc' onChange={onCheck}>Other</Checkbox>*!/*!/*/}
             {/*            /!*</div>*!/*/}
             {/*            /!*{isChecked && (*!/*/}
             {/*            /!*    <Input*!/*/}
             {/*            /!*        className='checkinput'*!/*/}
             {/*            /!*        value={inputValue}*!/*/}
             {/*            /!*        onChange={handleInputChange}*!/*/}
             {/*            /!*        type="text"*!/*/}
             {/*            /!*        placeholder="Type something..."*!/*/}
             {/*            /!*    />*!/*/}
             {/*            /!*)}*!/*/}

             {/*        /!*</Form.Item>*!/*/}
             {/*    </div>*/}
             {/*    /!*<Form.Item*!/*/}

             {/*    /!*    name='eventDesc'*!/*/}
             {/*    /!*    label="I will attend"*!/*/}
             {/*    /!*    rules={[{ required: true }]}>*!/*/}
             {/*    /!*    <div className='checkName'>*!/*/}
             {/*    /!*        <Checkbox  checked={checkValue1.includes("Welcoming Reception 29 May 2024")}  value="Welcoming Reception 29 May 2024" name='eventDesc' onChange={onCheck1}>Welcoming Reception 29 May 2024</Checkbox>*!/*/}
             {/*    /!*        <Checkbox checked={checkValue1.includes("Zangilan Trip 30 May 2024")} value="Zangilan Trip 30 May 2024" name='eventDesc' onChange={onCheck1}>Zangilan Trip 30 May 2024</Checkbox>*!/*/}
             {/*    /!*        <Checkbox checked={checkValue1.includes("Conference 31 May 2024")} value="Conference 31 May 2024"  name='eventDesc' onChange={onCheck1}>Conference 31 May 2024</Checkbox>*!/*/}
             {/*    /!*    </div>*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*    /!*<Form.Item className='radioName'*!/*/}
             {/*    /!*           name='disabilityDesc'*!/*/}
             {/*    /!*           label="Please confirm if you have any disability you want organizing team to consider:">*!/*/}
             {/*    /!*    <Radio.Group  onChange={onChange} value={value}>*!/*/}
             {/*    /!*        <div >*!/*/}
             {/*    /!*            <Space direction="vertical">*!/*/}
             {/*    /!*                <Radio name='disabilityDesc' value="yes">Yes</Radio>*!/*/}
             {/*    /!*                <Radio name='disabilityDesc' value="No">No</Radio>*!/*/}
             {/*    /!*                <Radio name='disabilityDesc' value="Other">Other</Radio>*!/*/}
             {/*    /!*                {isChecked1 && (*!/*/}
             {/*    /!*                    <Input*!/*/}
             {/*    /!*                        value={inputValue1}*!/*/}
             {/*    /!*                        onChange={handleInputChange1}*!/*/}
             {/*    /!*                        className='checkinput'*!/*/}
             {/*    /!*                        type="text"*!/*/}
             {/*    /!*                        placeholder="Type something..."*!/*/}
             {/*    /!*                    />*!/*/}
             {/*    /!*                )}*!/*/}
             {/*    /!*            </Space>*!/*/}
             {/*    /!*        </div>*!/*/}
             {/*    /!*    </Radio.Group>*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*    /!*<Form.Item*!/*/}

             {/*    /!*    label="Please select your dietary preference">*!/*/}
             {/*    /!*    <div className='checkName'>*!/*/}
             {/*    /!*        <Radio checked={checkValue2.includes("Non-Vegetarian")} type="checkbox"  value="Non-Vegetarian" onChange={onCheck2}>Non-Vegetarian</Radio>*!/*/}
             {/*    /!*        <Radio checked={checkValue2.includes("vegetarian")} type="checkbox"  value="vegetarian" onChange={onCheck2}>Vegetarian</Radio>*!/*/}
             {/*    /!*        <Radio checked={checkValue2.includes("Vegan")} type="checkbox"  value="Vegan" onChange={onCheck2}>Vegan</Radio>*!/*/}
             {/*    /!*        <Radio checked={checkValue2.includes("Other")} type="checkbox"  value="Other" onChange={onCheck2}>Other</Radio>*!/*/}
             {/*    /!*        {isChecked2 && (*!/*/}
             {/*    /!*            <Input*!/*/}
             {/*    /!*                value={inputValue2}*!/*/}
             {/*    /!*                onChange={handleInputChange2}*!/*/}
             {/*    /!*                className='checkinput'*!/*/}
             {/*    /!*                type="text"*!/*/}
             {/*    /!*                placeholder="Type something..."*!/*/}
             {/*    /!*            />*!/*/}
             {/*    /!*        )}*!/*/}
             {/*    /!*    </div>*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*    /!*<Form.Item*!/*/}
             {/*    /!*    className='cityInput'*!/*/}
             {/*    /!*    name="citizenship"*!/*/}
             {/*    /!*    label="Citizenship"*!/*/}
             {/*    /!*    help="Country"*!/*/}
             {/*    /!*    rules={[*!/*/}
             {/*    /!*        {  required: true },*!/*/}
             {/*    /!*    ]}*!/*/}
             {/*    /!*>*!/*/}
             {/*    /!*    <Select*!/*/}
             {/*    /!*        className='cityInput1'*!/*/}
             {/*    /!*        showSearch*!/*/}
             {/*    /!*        style={{ width: 200 }}*!/*/}
             {/*    /!*        placeholder="Please Select"*!/*/}
             {/*    /!*        optionFilterProp="children"*!/*/}
             {/*    /!*        filterOption={(input, option) =>  {*!/*/}
             {/*    /!*            return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*!/*/}
             {/*    /!*        }}*!/*/}
             {/*    /!*        filterSort={(optionA, optionB) => {*!/*/}
             {/*    /!*            return (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())*!/*/}
             {/*    /!*         }*!/*/}
             {/*    /!*        }*!/*/}
             {/*    /!*        options={[*!/*/}
             {/*    /!*            { value: 'Afghanistan', label: 'Afghanistan' },*!/*/}
             {/*    /!*            { value: 'Albania', label: 'Albania' },*!/*/}
             {/*    /!*            { value: 'Algeria', label: 'Algeria' },*!/*/}
             {/*    /!*            // { value: 'American', label: 'American' },*!/*/}
             {/*    /!*            { value: 'Andorra', label: 'Andorra' },*!/*/}
             {/*    /!*            { value: 'Angola', label: 'Angola' },*!/*/}
             {/*    /!*            { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },*!/*/}
             {/*    /!*            { value: 'Argentina', label: 'Argentina' },*!/*/}
             {/*    /!*            { value: 'Australia', label: 'Australia' },*!/*/}
             {/*    /!*            { value: 'Austria', label: 'Austria' },*!/*/}
             {/*    /!*            { value: 'Azerbaijan', label: 'Azerbaijan' },*!/*/}
             {/*    /!*            { value: 'Bahamas', label: 'Bahamas' },*!/*/}
             {/*    /!*            { value: 'Bahrain', label: 'Bahrain' },*!/*/}
             {/*    /!*            { value: 'Bangladesh', label: 'Bangladesh' },*!/*/}
             {/*    /!*            { value: 'Barbados', label: 'Barbados' },*!/*/}
             {/*    /!*            { value: 'Belarus', label: 'Belarus' },*!/*/}
             {/*    /!*            { value: 'Belgium', label: 'Belgium' },*!/*/}
             {/*    /!*            { value: 'Belize', label: 'Belize' },*!/*/}
             {/*    /!*            { value: 'Benin', label: 'Benin' },*!/*/}
             {/*    /!*            { value: 'Bhutan', label: 'Bhutan' },*!/*/}
             {/*    /!*            { value: 'Bolivia (Plurinational State of)', label: 'Bolivia (Plurinational State of)' },*!/*/}
             {/*    /!*            { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },*!/*/}
             {/*    /!*            { value: 'Botswana', label: 'Botswana' },*!/*/}
             {/*    /!*            { value: 'Brazil', label: 'Brazil' },*!/*/}
             {/*    /!*            { value: 'Brunei Darussalam', label: 'Brunei Darussalam' },*!/*/}
             {/*    /!*            { value: 'Bulgaria', label: 'Bulgaria' },*!/*/}
             {/*    /!*            { value: 'Burkina Faso', label: 'Burkina Faso' },*!/*/}
             {/*    /!*            { value: 'Burundi', label: 'Burundi' },*!/*/}
             {/*    /!*            { value: 'Cabo Verde', label: 'Cabo Verde' },*!/*/}
             {/*    /!*            { value: 'Cambodia', label: 'Cambodia' },*!/*/}
             {/*    /!*            { value: 'Cameroon', label: 'Cameroon' },*!/*/}
             {/*    /!*            { value: 'Canada', label: 'Canada' },*!/*/}
             {/*    /!*            { value: 'Central African Republic', label: 'Central African Republic' },*!/*/}
             {/*    /!*            { value: 'Chad', label: 'Chad' },*!/*/}
             {/*    /!*            { value: 'Chile', label: 'Chile' },*!/*/}
             {/*    /!*            { value: 'China', label: 'China' },*!/*/}
             {/*    /!*            { value: 'Colombia', label: 'Colombia' },*!/*/}
             {/*    /!*            { value: 'Comoros', label: 'Comoros' },*!/*/}
             {/*    /!*            { value: 'Congo', label: 'Congo' },*!/*/}
             {/*    /!*            { value: 'Costa Rica', label: 'Costa Rica' },*!/*/}
             {/*    /!*            { value: 'Côte D\'Ivoire', label: 'Côte D\'Ivoire' },*!/*/}
             {/*    /!*            { value: 'Croatia', label: 'Croatia' },*!/*/}
             {/*    /!*            { value: 'Cuba', label: 'Cuba' },*!/*/}
             {/*    /!*            { value: 'Cyprus', label: 'Cyprus' },*!/*/}
             {/*    /!*            { value: 'Czechia', label: 'Czechia' },*!/*/}
             {/*    /!*            { value: 'Democratic People\'s Republic of Korea', label: 'Democratic People\'s Republic of Korea' },*!/*/}
             {/*    /!*            { value: 'Democratic Republic of the Congo', label: 'Democratic Republic of the Congo' },*!/*/}
             {/*    /!*            { value: 'Denmark', label: 'Denmark' },*!/*/}
             {/*    /!*            { value: 'Djibouti', label: 'Djibouti' },*!/*/}
             {/*    /!*            { value: 'Dominica', label: 'Dominica' },*!/*/}
             {/*    /!*            { value: 'Dominican Republic', label: 'Dominican Republic' },*!/*/}
             {/*    /!*            { value: 'Ecuador', label: 'Ecuador' },*!/*/}
             {/*    /!*            { value: 'Egypt', label: 'Egypt' },*!/*/}
             {/*    /!*            { value: 'El Salvador', label: 'El Salvador' },*!/*/}
             {/*    /!*            { value: 'Equatorial Guinea', label: 'Equatorial Guinea' },*!/*/}
             {/*    /!*            { value: 'Eritrea', label: 'Eritrea' },*!/*/}
             {/*    /!*            { value: 'Estonia', label: 'Estonia' },*!/*/}
             {/*    /!*            { value: 'Ethiopia', label: 'Ethiopia' },*!/*/}
             {/*    /!*            { value: 'Fiji', label: 'Fiji' },*!/*/}
             {/*    /!*            { value: 'Finland', label: 'Finland' },*!/*/}
             {/*    /!*            { value: 'France', label: 'France' },*!/*/}
             {/*    /!*            { value: 'Gabon', label: 'Gabon' },*!/*/}
             {/*    /!*            { value: 'Gambia (Republic of The)', label: 'Gambia (Republic of The)' },*!/*/}
             {/*    /!*            { value: 'Georgia', label: 'Georgia' },*!/*/}
             {/*    /!*            { value: 'Germany', label: 'Germany' },*!/*/}
             {/*    /!*            { value: 'Ghana', label: 'Ghana' },*!/*/}
             {/*    /!*            { value: 'Greece', label: 'Greece' },*!/*/}
             {/*    /!*            { value: 'Grenada', label: 'Grenada' },*!/*/}
             {/*    /!*            { value: 'Guatemala', label: 'Guatemala' },*!/*/}
             {/*    /!*            { value: 'Guinea-Bissau', label: 'Guinea-Bissau' },*!/*/}
             {/*    /!*            { value: 'Guinea', label: 'Guinea' },*!/*/}
             {/*    /!*            { value: 'Guyana', label: 'Guyana' },*!/*/}
             {/*    /!*            { value: 'Haiti', label: 'Haiti' },*!/*/}
             {/*    /!*            { value: 'Honduras', label: 'Honduras' },*!/*/}
             {/*    /!*            { value: 'Hungary', label: 'Hungary' },*!/*/}
             {/*    /!*            { value: 'Iceland', label: 'Iceland' },*!/*/}
             {/*    /!*            { value: 'India', label: 'India' },*!/*/}
             {/*    /!*            { value: 'Indonesia', label: 'Indonesia' },*!/*/}
             {/*    /!*            { value: 'Iran (Islamic Republic of)', label: 'Iran (Islamic Republic of)' },*!/*/}
             {/*    /!*            { value: 'Iraq', label: 'Iraq' },*!/*/}
             {/*    /!*            { value: 'Ireland', label: 'Ireland' },*!/*/}
             {/*    /!*            { value: 'Israel', label: 'Israel' },*!/*/}
             {/*    /!*            { value: 'Italy', label: 'Italy' },*!/*/}
             {/*    /!*            { value: 'Jamaica', label: 'Jamaica' },*!/*/}
             {/*    /!*            { value: 'Japan', label: 'Japan' },*!/*/}
             {/*    /!*            { value: 'Jordan', label: 'Jordan' },*!/*/}
             {/*    /!*            { value: 'Kazakhstan', label: 'Kazakhstan' },*!/*/}
             {/*    /!*            { value: 'Kenya', label: 'Kenya' },*!/*/}
             {/*    /!*            { value: 'Kiribati', label: 'Kiribati' },*!/*/}
             {/*    /!*            { value: 'Kuwait', label: 'Kuwait' },*!/*/}
             {/*    /!*            { value: 'Kyrgyzstan', label: 'Kyrgyzstan' },*!/*/}
             {/*    /!*            { value: 'Lao People’s Democratic Republic', label: 'Lao People’s Democratic Republic' },*!/*/}
             {/*    /!*            { value: 'Latvia', label: 'Latvia' },*!/*/}
             {/*    /!*            { value: 'Lebanon', label: 'Lebanon' },*!/*/}
             {/*    /!*            { value: 'Lesotho', label: 'Lesotho' },*!/*/}
             {/*    /!*            { value: 'Liberia', label: 'Liberia' },*!/*/}
             {/*    /!*            { value: 'Libya', label: 'Libya' },*!/*/}
             {/*    /!*            { value: 'Liechtenstein', label: 'Liechtenstein' },*!/*/}
             {/*    /!*            { value: 'Lithuania', label: 'Lithuania' },*!/*/}
             {/*    /!*            { value: 'Luxembourg', label: 'Luxembourg' },*!/*/}
             {/*    /!*            { value: 'Madagascar', label: 'Madagascar' },*!/*/}
             {/*    /!*            { value: 'Malaysia', label: 'Malaysia' },*!/*/}
             {/*    /!*            { value: 'Malawi', label: 'Malawi' },*!/*/}
             {/*    /!*            { value: 'Maldives', label: 'Maldives' },*!/*/}
             {/*    /!*            { value: 'Mali', label: 'Mali' },*!/*/}
             {/*    /!*            { value: 'Malta', label: 'Malta' },*!/*/}
             {/*    /!*            { value: 'Marshall Islands', label: 'Marshall Islands' },*!/*/}
             {/*    /!*            { value: 'Mauritania', label: 'Mauritania' },*!/*/}
             {/*    /!*            { value: 'Mauritius', label: 'Mauritius' },*!/*/}
             {/*    /!*            { value: 'Mexico', label: 'Mexico' },*!/*/}
             {/*    /!*            { value: 'Micronesia (Federated States of)', label: 'Micronesia (Federated States of)' },*!/*/}
             {/*    /!*            { value: 'Monaco', label: 'Monaco' },*!/*/}
             {/*    /!*            { value: 'Mongolia', label: 'Mongolia' },*!/*/}
             {/*    /!*            { value: 'Morocco', label: 'Morocco' },*!/*/}
             {/*    /!*            { value: 'Mozambique', label: 'Mozambique' },*!/*/}
             {/*    /!*            { value: 'Myanmar', label: 'Myanmar' },*!/*/}
             {/*    /!*            { value: 'Namibia', label: 'Namibia' },*!/*/}
             {/*    /!*            { value: 'Nauru', label: 'Nauru' },*!/*/}
             {/*    /!*            { value: 'Nepal', label: 'Nepal' },*!/*/}
             {/*    /!*            { value: 'Netherlands (Kingdom of the)', label: 'Netherlands (Kingdom of the)' },*!/*/}
             {/*    /!*            { value: 'New Zealand', label: 'New Zealand' },*!/*/}
             {/*    /!*            { value: 'Nicaragua', label: 'Nicaragua' },*!/*/}
             {/*    /!*            { value: 'Niger', label: 'Niger' },*!/*/}
             {/*    /!*            { value: 'Nigeria', label: 'Nigeria' },*!/*/}
             {/*    /!*            { value: 'North Macedonia', label: 'North Macedonia' },*!/*/}
             {/*    /!*            { value: 'Norway', label: 'Norway' },*!/*/}
             {/*    /!*            { value: 'Oman', label: 'Oman' },*!/*/}
             {/*    /!*            { value: 'Pakistan', label: 'Pakistan' },*!/*/}
             {/*    /!*            { value: 'Palau', label: 'Palau' },*!/*/}
             {/*    /!*            { value: 'Panama', label: 'Panama' },*!/*/}
             {/*    /!*            { value: 'Papua New Guinea', label: 'Papua New Guinea' },*!/*/}
             {/*    /!*            { value: 'Paraguay', label: 'Paraguay' },*!/*/}
             {/*    /!*            { value: 'Peru', label: 'Peru' },*!/*/}
             {/*    /!*            { value: 'Philippines', label: 'Philippines' },*!/*/}
             {/*    /!*            { value: 'Poland', label: 'Poland' },*!/*/}
             {/*    /!*            { value: 'Portugal', label: 'Portugal' },*!/*/}
             {/*    /!*            { value: 'Qatar', label: 'Qatar' },*!/*/}
             {/*    /!*            { value: 'Republic of Korea', label: 'Republic of Korea' },*!/*/}
             {/*    /!*            { value: 'Republic of Moldova', label: 'Republic of Moldova' },*!/*/}
             {/*    /!*            { value: 'Romania', label: 'Romania' },*!/*/}
             {/*    /!*            { value: 'Russian Federation', label: 'Russian Federation' },*!/*/}
             {/*    /!*            { value: 'Rwanda', label: 'Rwanda' },*!/*/}
             {/*    /!*            { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },*!/*/}
             {/*    /!*            { value: 'Saint Lucia', label: 'Saint Lucia' },*!/*/}
             {/*    /!*            { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },*!/*/}
             {/*    /!*            { value: 'Samoa', label: 'Samoa' },*!/*/}
             {/*    /!*            { value: 'San Marino', label: 'San Marino' },*!/*/}
             {/*    /!*            { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },*!/*/}
             {/*    /!*            { value: 'Saudi Arabia', label: 'Saudi Arabia' },*!/*/}
             {/*    /!*            { value: 'Senegal', label: 'Senegal' },*!/*/}
             {/*    /!*            { value: 'Serbia', label: 'Serbia' },*!/*/}
             {/*    /!*            { value: 'Seychelles', label: 'Seychelles' },*!/*/}
             {/*    /!*            { value: 'Sierra Leone', label: 'Sierra Leone' },*!/*/}
             {/*    /!*            { value: 'Singapore', label: 'Singapore' },*!/*/}
             {/*    /!*            { value: 'Slovakia', label: 'Slovakia' },*!/*/}
             {/*    /!*            { value: 'Slovenia', label: 'Slovenia' },*!/*/}
             {/*    /!*            { value: 'Solomon Islands', label: 'Solomon Islands' },*!/*/}
             {/*    /!*            { value: 'Somalia', label: 'Somalia' },*!/*/}
             {/*    /!*            { value: 'South Africa', label: 'South Africa' },*!/*/}
             {/*    /!*            { value: 'South Sudan', label: 'South Sudan' },*!/*/}
             {/*    /!*            { value: 'Spain', label: 'Spain' },*!/*/}
             {/*    /!*            { value: 'Sri Lanka', label: 'Sri Lanka' },*!/*/}
             {/*    /!*            { value: 'Sudan', label: 'Sudan' },*!/*/}
             {/*    /!*            { value: 'Suriname', label: 'Suriname' },*!/*/}
             {/*    /!*            { value: 'Sweden', label: 'Sweden' },*!/*/}
             {/*    /!*            { value: 'Switzerland', label: 'Switzerland' },*!/*/}
             {/*    /!*            { value: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },*!/*/}
             {/*    /!*            { value: 'Tajikistan', label: 'Tajikistan' },*!/*/}
             {/*    /!*            { value: 'Thailand', label: 'Thailand' },*!/*/}
             {/*    /!*            {value: 'Timor-Leste', label: 'Timor-Leste' },*!/*/}
             {/*    /!*            { value: 'Togo', label: 'Togo' },*!/*/}
             {/*    /!*            { value: 'Tonga', label: 'Tonga' },*!/*/}
             {/*    /!*            { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },*!/*/}
             {/*    /!*            { value: 'Tunisia', label: 'Tunisia' },*!/*/}
             {/*    /!*            { value: 'Türkiye', label: 'Türkiye' },*!/*/}
             {/*    /!*            { value: 'Turkmenistan', label: 'Turkmenistan' },*!/*/}
             {/*    /!*            { value: 'Tuvalu', label: 'Tuvalu' },*!/*/}
             {/*    /!*            { value: 'Uganda', label: 'Uganda' },*!/*/}
             {/*    /!*            { value: 'Ukraine', label: 'Ukraine' },*!/*/}
             {/*    /!*            { value: 'United Arab Emirates', label: 'United Arab Emirates' },*!/*/}
             {/*    /!*            { value: 'United Kingdom of Great Britain and Northern Ireland', label: 'United Kingdom of Great Britain and Northern Ireland' },*!/*/}
             {/*    /!*            { value: 'United Republic of Tanzania', label: 'United Republic of Tanzania' },*!/*/}
             {/*    /!*            { value: 'United States of America', label: 'United States of America' },*!/*/}
             {/*    /!*            { value: 'Uruguay', label: 'Uruguay' },*!/*/}
             {/*    /!*            { value: 'Uzbekistan', label: 'Uzbekistan' },*!/*/}
             {/*    /!*            { value: 'Vanuatu', label: 'Vanuatu' },*!/*/}
             {/*    /!*            { value: 'Venezuela, Bolivarian Republic of', label: 'Venezuela, Bolivarian Republic of' },*!/*/}
             {/*    /!*            { value: 'Viet Nam', label: 'Viet Nam' },*!/*/}
             {/*    /!*            { value: 'Yemen', label: 'Yemen' },*!/*/}
             {/*    /!*            { value: 'Zambia', label: 'Zambia' },*!/*/}
             {/*    /!*            { value: 'Zimbabwe', label: 'Zimbabwe' },*!/*/}

             {/*    /!*        ]}*!/*/}
             {/*    /!*    />*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*    /!*<Form.Item*!/*/}
             {/*    /!*    className='passportNumber'*!/*/}
             {/*    /!*    name="passportNumber" label="Passport number (as indicated in the Passport)" rules={[{ required: true }]}>*!/*/}
             {/*    /!*    <Input className='cityInput2' />*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*    /!*<Form.Item*!/*/}

             {/*    /!*    className='browseInput'*!/*/}
             {/*    /!*    label= "Please upload your passport (Max size 5 MB)"*!/*/}
             {/*    /!*    name=''*!/*/}
             {/*    /!*    rules={[{ required: true}]}>*!/*/}
             {/*    /!*    {uploadError && <span className="file">  only a *.png,*.jpeg,*.pdf file</span>}*!/*/}
             {/*    /!*    <Input*!/*/}
             {/*    /!*        onChange={uploadContent}*!/*/}
             {/*    /!*        className='browseButton'*!/*/}
             {/*    /!*        type='file'*!/*/}
             {/*    /!*        multiple={true}*!/*/}
             {/*    /!*    />*!/*/}
             {/*    /!*</Form.Item>*!/*/}

             {/*    /!*<Form.Item   className='buttonInput' >*!/*/}
             {/*    /!*    <Space >*!/*/}
             {/*    /!*        <div className='errorspace'>*!/*/}
             {/*    /!*        {error && <span className="errormessage"> You will be reverted to by email in due course regarding the sponsorship request </span>}*!/*/}
             {/*    /!*        <Button*!/*/}
             {/*    /!*            htmlType="submit"*!/*/}
             {/*    /!*            onClick={handleSubmit}*!/*/}
             {/*    /!*            disabled={loading}*!/*/}
             {/*    /!*            loading={loading}*!/*/}

             {/*    /!*        >Submit Form</Button>*!/*/}
             {/*    /!*        </div>*!/*/}

             {/*    /!*    </Space>*!/*/}
             {/*    /!*</Form.Item>*!/*/}
             {/*</div>*/}
            {/*</Form>*/}

        </div>
    );
}

export default FormComponent;
