import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom'
import LoginComponent from "./components/LoginComponent/LoginComponent";
import TableComponent from "./components/TableComponent/TableComponent";
import FormComponent from "./components/FormComponent/FormComponent";
import SuccessModal from "./components/popupModals/SuccessModal";
import ErrorModal from "./components/popupModals/ErrorModal";
import { Provider } from 'react-redux'
import {store} from "./store/store";


const router = createBrowserRouter([
    {
        path:'/',
        element:<App/>,
        children:[
            {
                path:'/',
                element:<FormComponent/>
            },
            {
                path:'/login',
                element:<LoginComponent/>
            },
            {
                path:'/table',
                element:<TableComponent/>
            },
            {
                path:'/success',
                element:<SuccessModal/>
            },
            {
                path:'/error',
                element:<ErrorModal/>
            },
            {
                path:"*",
                element:<FormComponent/>
                // element:<Navigate to="/"/>

            }


        ]
    },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
      <Provider store={store}>
      <RouterProvider router={router}/>
      </Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
