import React, {useEffect, useState} from 'react';
import './LoginComponent.css'
import {Button, Form, Input} from "antd";
import {NavLink, useNavigate} from "react-router-dom";
import {getLogin} from "../../services";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";
import {newLogin, userActions} from "../../store/user.slice";

const LoginComponent = () => {
    const [form] = Form.useForm();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError]=useState('');
    const [visible,setVisible]=useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>()
    // const {jwt, loginErrorMessage} = useSelector((s:RootState) => s.user);
    const jwt = useSelector((s:RootState) => s.user.jwt);
    const loginErrorMessage = useSelector((s:RootState) => s.user.loginErrorMessage);



    useEffect(()=> {

      if(jwt) {
          console.log(jwt)
        navigate('/table');
    }
    },[jwt,navigate]);


    const handleSubmit = (e:any) => {
        // dispatch(userActions.clearLoginError());
        e.preventDefault();
    };
    const loginHandler = async (username:string,password:string)=>{
         dispatch(userActions.clearLoginError());
        dispatch(newLogin({username,password}));
        // const req = await getLogin({
        //     "username": username,
        //     "password": password
        // })
        // console.log(req.status)
        // if(req.status === 200) {
        //     setError(req.data.message)
        //     setVisible(req.data.success)
        //
        //     if (req.data.success) {
        //         dispatch(userActions.addJwt(req.data.result.token))
        //         navigate('/table');
        //         // localStorage.setItem("auth_token", req.data.result.token)
        //     }
        // } else if(req.status === 503) {
        //     window.location.reload();
        //
        // }

    }
    return (
        <div>
                <Form
                form={form}
                className='LoginForm'
                >

                    <p className={'errormessage'}> {visible ? "" : loginErrorMessage}</p>
                <div>

                    <label className='loginLabel'>Username:</label>

                    <Input

                        name='username'
                        className='loginInput'
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label className='loginLabel'>Password:</label>
                    <Input
                        name='password'
                        className='loginInput2'
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>


                       <Button
                            className='loginButton'

                            htmlType="submit"
                        onClick={()=>loginHandler(username,password)}
                        >Login</Button>

            </Form>
        </div>
    );
};
export default LoginComponent;
