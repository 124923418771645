import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {loadState} from "./storage";
import {getLogin} from "../services";
import {AxiosError} from "axios";



export const JWT_PERSISTENT_STATE = 'userData';

export interface UserPersistentState{
    jwt:string| null;
}
export interface UserState {
    jwt:string| null;
    loginErrorMessage?:string| null;

}
const initialState: UserState = {
    jwt: loadState<UserPersistentState>(JWT_PERSISTENT_STATE)?.jwt??null,
    loginErrorMessage:null

}

export const newLogin = createAsyncThunk('user/login',
    async (params:{username:string, password:string})=>{
    try{
        const {data} = await getLogin({
            "username": params.username,
            "password": params.password
        })
        return data;
    } catch(e){
        if (e instanceof AxiosError){
            throw (e)
        }
    }
    }
    );

    export const userSlice:any = createSlice({
    name:'user',
    initialState,
    reducers:{
        // addJwt:(state,action:PayloadAction<string>) =>{
        //     state.jwt=action.payload
        // },
        logout:(state) =>{
            state.jwt=null;
        },
        clearLoginError:(state)=>{
            state.loginErrorMessage = undefined
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(newLogin.fulfilled,(state,action)=>{
            if (!action.payload){
                return;
            }
            if(action.payload.success){
                state.jwt=action.payload.result.token

            }else{

                state.loginErrorMessage = action.payload.message;

            }
        })
        builder.addCase(newLogin.rejected, (state, action) => {
            state.loginErrorMessage = action.error.message;
            console.log(action.error.message)
        });
    }
})

export default userSlice.reducer;
export const userActions = userSlice.actions