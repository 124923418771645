import React, {useState} from 'react';
import {Alert,  Space} from 'antd';
import './SuccessModal.css'
import {NavLink} from "react-router-dom";






const SuccessModal: React.FC = () => {

    const [showError, setShowError] = useState(false);




return(
    <div className={'success'}>
        <Space  direction="vertical" style={{ width: '40%',height:80}}>
            <NavLink to={'/'}>

                <Alert
                    className='successText'
                    message="success registration"
                    type="success"
                    showIcon
                    // action={
                    //     <Button size="small" type="text">
                    //         ok
                    //     </Button>
                    // }
                    closable
                />
            </NavLink>

        </Space>
    </div>

 ); }





export default SuccessModal;