
import axios from "axios";

export const API_URL = "https://registration.anama.gov.az";
// export const API_URL = "http://localhost:7770";

export const registerAttendee = async(request:any) => {
    const{data}= await axios.post<any>( `${API_URL}/conference-ws/v1/register`,request);
    return data;
};

export const fileupload = async(formData :any) => {
    const{data}= await axios.post<any>( `${API_URL}/conference-ws/v1/file-upload`,formData,
        {
            headers: {
                "Content-type": "multipart/form-data"
            },
        });
    return data;
};

export const getLogin = async(request:any) => {
    const data = await axios.post<any>( `${API_URL}/conference-ws/v1/users/login`,request);
    return data;
};

export const getAllAttendees = async (jwt:any): Promise<string> => {

    const http = axios.create({
        baseURL: API_URL,
        headers : {
            Accept: "application/json",
            Authorization: `Bearer ${jwt}`
        },
        withCredentials: false,
    });
    http.interceptors.response.use(
        function(response) {
            console.log(response)
            return response
        },
        function(error) {
            if (error.response.status === 401) {
                return { "authorized" : false };
            }
            return { "authorized" : false };
            // return Promise.reject(error)
        },
    )

    const { data }= await http.get<any>(`${API_URL}/conference-ws/v1/attendee/all`);
    console.log("data")
    console.log(data)
    return data;
};

export const fileDownload = async (id: any)=>{
    const http = axios.create({
        baseURL: API_URL,
        headers : {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
        },
        withCredentials: false,
    });
    http.interceptors.response.use(
        function(response) {
            console.log(response)
            return response
        },
        function(error) {
            console.log(error)
            if (error.response.status === 401) {
                return { "authorized" : false };
            }
            return { "authorized" : false };
            // return Promise.reject(error)
        },
    )
    const {data}=await http.get<any>(`${API_URL}/conference-ws/v1/file-download/${id}`);
    return data;
}

export const checkSponsorhip = async (formdata:any)=>{
    const {data}= await axios.post<any>(`${API_URL}/conference-ws/v1/check-sponsorhip`,formdata)
    return data
}

export const updateStatus = async(id:any,status:any)=>{
    const http = axios.create({
        baseURL: API_URL,
        headers : {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem('auth_token')}`
        },
        withCredentials: false,
    });
    http.interceptors.response.use(
        function(response) {
            console.log(response)
            return response
        },
        function(error) {
            console.log(error)
            if (error.response.status === 401) {
                return { "authorized" : false };
            }
            return { "authorized" : false };
            // return Promise.reject(error)
        },
    )
    const {data}=await http.put<any>(`${API_URL}/conference-ws/v1/update-status/${id}/${status}`);
    return data;

}