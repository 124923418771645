import React from 'react';
import logo from './assets/Təlimat başlıq.jpeg';
import login from './assets/login-svgrepo-com.svg'
import './App.css';
import {NavLink, Outlet, useLocation} from "react-router-dom";





function App() {
    const location = useLocation();
    const hideIcon = location.pathname === '/';


    return (
    <div className="App">
        <div className={'all'}>
            <div className="logos">
                <header className="App-header">
                    <div className={'login-all'}>
                        <div className={'img-1'}>
                            <img src={logo} className="headers" alt="" />
                        </div>
                        {/*<div className={'img-2'}>*/}
                        {/*    { hideIcon && <NavLink to='/login'>*/}
                        {/*        <img*/}

                        {/*            src={login}*/}
                        {/*            className="login"*/}
                        {/*            alt="Login"/>*/}

                        {/*    </NavLink>}*/}
                        {/*</div>*/}


                    </div>

                </header>


            </div>
            <div className={'back'}>
                <Outlet/>

            </div>
        </div>

    </div>
  );
}

export default App;
