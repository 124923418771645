import {Button, Table} from "antd";
import React, {useEffect, useState} from "react";
import {fileDownload, getAllAttendees, updateStatus} from "../../services";
import './TableComponent.css'
import {NavLink, useNavigate} from "react-router-dom";
import exit from "../../assets/exit-25.svg";
import excellogo from "../../assets/Download.svg";
import * as XLSX from "xlsx"
import  trueIcon from "../../assets/true.svg"
import falseIcon from "../../assets/false.svg"
import {DeleteOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store/store";




const TableComponent = () =>{
    const [table, setTable]=useState<any>()
    const [loading,setLoading]= useState(false)
    const navigate = useNavigate();
    const jwt = useSelector((s:RootState) => s.user.jwt);

    const login = async () => {
        setLoading(true)
      const data: any  =  await getAllAttendees(jwt);

        if(data === undefined || data === null) {

            localStorage.setItem("auth_token", "")
            navigate('/login');
        } else {
            setTable(data?.data)
            setLoading(false)
        }
    };
    useEffect(()=>{

        if(!loading)
            login()
    },[])

    const handleOk = async (atendid:any,no:any)=>{

        const confirm = await updateStatus(atendid,no)
        if(confirm.success===true){
            login()
        }

    }
    // const handleReject =async (id:any)=>{
    //     const rej = await updateStatus(id,2)
    //     if(rej.success===true){
    //         login()
    //     }
    // }


    function downloadPDF (fileName: any, fileBase64: any) {
        const linkSource = `data:$application/download;base64,${fileBase64}`
        const downloadLink = document.createElement('a')
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
    }

    const handleClick= async (id: any)=>{
        const pdfData = await fileDownload(id)

        if(pdfData.result !== undefined && pdfData.result !== null && pdfData.result.fileName !== undefined && pdfData.result.fileName !== null && pdfData.result.base64Data !== undefined && pdfData.result.base64Data !== null) {
            downloadPDF(pdfData.result.fileName, pdfData.result.base64Data)
        }
    }
    const DownloadIco = ({ className = '' }) => {
        return <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white"/>
            <path d="M18 11L20 13H18V11ZM17 14H20V20.5C20 20.6326 19.9473 20.7598 19.8536 20.8536C19.7598 20.9473 19.6326 21 19.5 21H12.5C12.3674 21 12.2402 20.9473 12.1464 20.8536C12.0527 20.7598 12 20.6326 12 20.5V11.5C12 11.3674 12.0527 11.2402 12.1464 11.1464C12.2402 11.0527 12.3674 11 12.5 11H17V14ZM13.5 13C13.5 13.1326 13.5527 13.2598 13.6464 13.3536C13.7402 13.4473 13.8674 13.5 14 13.5H15.5C15.6326 13.5 15.7598 13.4473 15.8536 13.3536C15.9473 13.2598 16 13.1326 16 13C16 12.8674 15.9473 12.7402 15.8536 12.6464C15.7598 12.5527 15.6326 12.5 15.5 12.5H14C13.8674 12.5 13.7402 12.5527 13.6464 12.6464C13.5527 12.7402 13.5 12.8674 13.5 13ZM18.5 19C18.5 18.8674 18.4473 18.7402 18.3536 18.6464C18.2598 18.5527 18.1326 18.5 18 18.5H14C13.8674 18.5 13.7402 18.5527 13.6464 18.6464C13.5527 18.7402 13.5 18.8674 13.5 19C13.5 19.1326 13.5527 19.2598 13.6464 19.3536C13.7402 19.4473 13.8674 19.5 14 19.5H18C18.1326 19.5 18.2598 19.4473 18.3536 19.3536C18.4473 19.2598 18.5 19.1326 18.5 19ZM14 16.5C13.8674 16.5 13.7402 16.5527 13.6464 16.6464C13.5527 16.7402 13.5 16.8674 13.5 17C13.5 17.1326 13.5527 17.2598 13.6464 17.3536C13.7402 17.4473 13.8674 17.5 14 17.5H18C18.1326 17.5 18.2598 17.4473 18.3536 17.3536C18.4473 17.2598 18.5 17.1326 18.5 17C18.5 16.8674 18.4473 16.7402 18.3536 16.6464C18.2598 16.5527 18.1326 16.5 18 16.5H14Z" fill="#666687"/>
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="white"/>
        </svg>
    }

    const handleExit =()=>{

    }

// @ts-ignore
    // @ts-ignore
    // @ts-ignore
    // @ts-ignore

    const sortOrder:any="descend"

  const columns = [
     {
       title: 'Registration Date',
       dataIndex: 'registrationDate',
       key: '1',
       sorter: (a: any, b: any) => new Date(a.registrationDate).getTime() - new Date(b.registrationDate).getTime(),
       // sortDirections:sort,
         defaultSortOrder:sortOrder
     },
    {
        title: 'Full Name',
        dataIndex: 'firstName',
        key: '2',

    },
    {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: '3',
    },
    {
        title: 'Organization/Company Name',
        dataIndex: 'companyName',
        key: 'CompanyName',
    },
    {
        title: 'Position/Title',
        dataIndex: 'positionName',
        key: 'PositionTitle',
    },
    {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'Email',
    },
    {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'PhoneNumber',
    },
    {
        title: 'Please indicate if you require sponsorship to attend the event',
        dataIndex: 'sponsorDesc',
        key: 'attendtheevent',
    },
    {
        title: 'I will attend',
        dataIndex: 'eventDesc',
        key: 'Iwillattend',
    },
    {
        title: 'Please confirm if you have any disability you want organizing team to consider:',
        dataIndex: 'disabilityDesc',
        key: 'teamtoconsider',
    },
    {
        title: 'Please select your dietary preference',
        dataIndex: 'dietaryDesc',
        key: 'dietarypreference',
    },
    {
        title: 'Citizenship',
        dataIndex: 'citizenship',
        key: 'Citizenship',
    },
    {
        title: 'Passport number (as indicated in the Passport)',
        dataIndex: 'passportNumber',
        key: 'Passport',
    },
        {
            title: 'Status',
            dataIndex: 'registrationStatus',
            key: 'status',
            render:(status:string)=>{
                if(status==="APPROVED"){
                    return "APPROVED"
                }else if(status==="REJECTED"){
                    return "REJECTED"
                }else if(status==="PENDING"){
                    return "PENDING"
                }else if(status==="CANCELLED"){
                    return "CANCELLED"
                }
            }
        },
      {
          title: 'isSameCompany',
          dataIndex: 'isSameCompany',
          key: 'isSameCompany',
          render:(isSameCompany:any)=>{
              if(isSameCompany){
                  return "true"
              } else if(!isSameCompany){
                  return "false"
              }
          }
      },
    {
        title: 'File',
        dataIndex: 'fileId',
        key: 'icon',
        // render: (record: any, text: string) => {
        //     return <div>{statusRender(record)}</div>
        // },
        // render: (record: any, text: string) => {
        //     <Button onClick={() => handleClick(record.fileId)} className="iconButton">
        //         <DownloadIco/> </Button>
        // }
        render:(record: any)=> {

            return <Button onClick={() => handleClick(record)} className="iconButton">
                <DownloadIco/> </Button>
        }
    },
        {
            title: 'Confirm',
            dataIndex: 'registrationStatus',
            key: 'icons',
            render:(text: string , record: any)=> {

                if(text==="PENDING"){
                    return (
                        <div style={{display:"flex"}}>
                            <Button  onClick={()=>handleOk(record.attendeeId,1)} className="iconButton">
                                <img  style={{height:30}} src={trueIcon}/>
                            </Button>
                            <Button  onClick={()=>handleOk(record.attendeeId,2)}  className="iconButton">
                                <img  style={{height:30}} src={falseIcon}/>
                            </Button>
                        </div>
                    )
                }else if(text==="APPROVED"){
                    return (<div>   <Button className="iconButton" onClick={()=>handleOk(record.attendeeId,3)}> <DeleteOutlined/> </Button> </div>)
                }else if(text==="REJECTED"){
                    // return( <div> <img style={{height:15}} src={redCircle} /> </div>)
                }
                },

        },

];

   const  downloadExcel = async () =>{
       const tempData: React.SetStateAction<any[]> = []
       let name = "Full Name"
       let lastName = "Last Name"
       let company = "Organization/Company Name"
       let position = "Position/Title"
       let email = "	E-mail"
       let number = "Phone Number"
       let sponsorship = "Please indicate if you require sponsorship to attend the event"
       let attend = "I will attend"
       let confirm = "	Please confirm if you have any disability you want organizing team to consider:"
       let preference = "	Please select your dietary preference"
       let citizenship = "Citizenship"
       let passport = "Passport number (as indicated in the Passport)"
       let file = "file"
       table.map((row:any)=>{

           tempData.push({
               [name]:row.firstName,
               [lastName]:row.lastName,
               [company]:row.companyName,
               [position]:row.positionName,
               [email]:row.email,
               [number]:row.phoneNumber,
               [sponsorship]:row.sponsorDesc,
               [attend]:row.eventDesc,
               [confirm]:row.disabilityDesc,
               [preference]:row.dietaryDesc,
               [citizenship]:row.citizenship,
               [passport]:row.passportNumber,
               [file]:row.file,
           })
       })


       let wb = XLSX.utils.book_new(),
           ws= XLSX.utils.json_to_sheet(tempData);

       XLSX.utils.book_append_sheet(wb, ws, 'ExcelFile');

       XLSX.writeFile(wb,'Exceltransfer.xlsx')

   }
return(
    <div className='img-all'>
        <div> <img src={excellogo} style={{height:30,marginLeft:"96%",cursor:"pointer"}} onClick={downloadExcel} className="imgexcell" alt="" /></div>
       <div> 
           {/*<NavLink to={'/'}><img src={exit} onClick={handleExit} className="exiticon" alt="" />   </NavLink>*/}

       </div>
        <div className='tableic'><Table
            // sortDirections={["descend",]}
            dataSource={table}
                                        rowClassName={(record:any)=>{
                                            const redclass:any= 'red-class'
                                            const greenclass:any = 'green-class'
                                           if(record.registrationStatus==='REJECTED'){
                                               return redclass
                                           }else if (record.registrationStatus==='APPROVED'){
                                               return greenclass
                                           }else if (record.registrationStatus==='CANCELLED'){
                                               return redclass
                                           }
                                        }
                                        }
                                        columns={columns} /> </div>

    </div>




)}
export default TableComponent;
