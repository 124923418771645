import React, {useState} from 'react';
import { Alert, Space } from 'antd';
import {NavLink} from "react-router-dom";
import './SuccessModal.css'





const ErrorModal: React.FC = () => {






    return(
<div className={"success"}>
    <Space direction="vertical" style={{ width: '40%', margin:33,height:80}}>
    <NavLink to={'/'}>
        <Alert style={{ width: '80%', margin:65,height:80}}
               className='.ant-alert-error'
               message="Error"
               description=""
               type="error"
               showIcon
               closable
        />
    </NavLink>

</Space></div>

    ); }



export default ErrorModal;